import React from 'react';
// import luckypets from '../../assets/LuckyPetsUnited.png'
// import Mars from '../../assets/Marsforecast.png'

function Welcome(props) {
    // const contactrender = () => {
    //     switch (props.currentpage.name) {
    //         case 'Contact':
    //             return <ContactForm/>;

    //             default:
    //                 return <Welcome/>    

    // }}
    return (

        <div className='currentpage'>
            {/* <h1>Welcome! My name is Fani and I am a Full Stack Web Developper</h1>
            <h2>Lets Build something amazing together</h2> */}
  Website coming soon!
            {/* <a href={ContactForm}> <ContactForm/></a> */}

            {/* < Link to={'/ContactForm'} >Contact</Link> */}

        </div>
    )

}


export default Welcome